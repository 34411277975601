import React, { useState, useEffect } from 'react';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  getStorage, ref, uploadBytes, getDownloadURL,
} from 'firebase/storage';
import {
  doc,
  updateDoc,
} from 'firebase/firestore';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, CircularProgress, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloudUpload from '@mui/icons-material/CloudUpload';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';

export default function PDFBranding() {
  const firestore = useFirestore();
  const [globalState] = useGlobal();

  const appDocRef = doc(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates`,
  );
  const appData = useFirestoreDocDataOnce(appDocRef);

  if (appData.status !== 'success') {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return <PDFBrandingForm appdDocData={appData.data} appDocRef={appDocRef} />;
}

function PDFBrandingForm({ appdDocData, appDocRef }) {
  const storage = getStorage();
  const [logoURL, setLogoURL] = useState('');
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [loadingState, setLoadingState] = useState('initial');
  const logoRef = ref(
    storage,
    `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates/logo.jpg`,
  );

  useEffect(() => {
    if (loadingState === 'initial') {
      setLoadingState('done');
      getDownloadURL(logoRef)
        .then((url) => {
          setLogoURL(url);
        })
        .catch((error) => {
          console.warn('Error downloading logo:', error);
        });
    }
  }, [loadingState, logoRef]);

  const validate = (values) => {
    const errors = {};

    if (!values.companyName) {
      errors.companyName = t('validations.required');
    }

    return errors;
  };

  const formik = useFormik({
    validate,
    initialValues: {
      companyName: appdDocData.companyPdfDisplayName || '',
      file: '',
    },
    onSubmit: async (values) => {
      try {
        if (values.file && values.file !== '') {
          uploadBytes(logoRef, values.file)
            .then(() => {
              getDownloadURL(logoRef)
                .then((url) => {
                  setLogoURL(url);
                })
                .catch((error) => {
                  console.error('Error fetching new logo URL:', error);
                });
            })
            .catch((error) => {
              globalActions.setSnackbarMessage({ message: `Error uploading logo: ${error}`, severity: 'error' });
            });
        }

        await updateDoc(appDocRef, {
          companyPdfDisplayName: values.companyName,
        });
        globalActions.setSnackbarMessage({ message: t('operation.save_success'), severity: 'success' });
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
  });

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 200 * 1024; // 200KB

    if (file.type !== 'image/jpeg') {
      globalActions.setSnackbarMessage({ message: t('admin.only_jpg'), severity: 'error' });
      return;
    }

    if (file.size > maxSize) {
      globalActions.setSnackbarMessage({ message: t('admin.logo_max_size'), severity: 'error' });
    }

    formik.setFieldValue('file', file);
  };

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 3, justifyContent: 'center', alignItems: 'center',
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <Typography variant="h6">
        {t('admin.pdf_branding_info')}
        {' '}
      </Typography>
      {!formik.values.file?.name && (
      <div>
        {logoURL && <img style={{ width: 200 }} src={logoURL} alt="Logo" />}
      </div>
      )}
      {formik.values.file?.name
        && (
        <Typography sx={{
          border: 1,
          p: 1,
          borderRadius: 1,
          borderStyle: 'dashed',
        }}
        >
          {formik.values.file?.name}
        </Typography>
        )}
      <div>
        <input
          accept="image/jpeg"
          id="logo-upload"
          type="file"
          onChange={handleLogoChange}
          style={{ display: 'none' }}
        />
        <Button
          disabled={formik.values.file?.name !== undefined}
          variant="outlined"
          component="label"
          htmlFor="logo-upload"
          startIcon={<AddPhotoAlternateIcon />}
        >
          {t('admin.company_logo')}
        </Button>
      </div>
      <TextField
        id="companyName"
        name="companyName"
        type="text"
        label={t('admin.company_name')}
        onChange={formik.handleChange}
        value={formik.values.companyName}
        error={formik.touched.companyName && Boolean(formik.errors.companyName)}
        helperText={formik.touched.companyName && formik.errors.companyName}
      />
      <LoadingButton
        loading={formik.isSubmitting}
        type="submit"
        variant="contained"
        endIcon={<CloudUpload />}
      >
        {t('confirm')}
      </LoadingButton>
    </Box>
  );
}
