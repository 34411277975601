import React from 'react';
import { navigatorNetworkState } from 'components/utils';
import useGlobalHook from './useGlobalHook';
import * as actions from './actions';

const initialState = {
  cookiesAccepted: false,
  networkState: navigatorNetworkState(),
  userStatus: 'initial',
  globalSnackbar: { display: false, mesage: '', severity: 'info' },
  userPlan: '',
  roles: [],
  activeOrganization: '',
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
