import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Paper from '@mui/material/Paper';

export default function Filters(props) {
  const { t } = useTranslation();
  const {
    transporter, handleChangeTransporter, startDate, handleStartDateChange, handleEndDateChange, endDate, transporters,
  } = props;

  return (
    <Paper elevation={0} sx={{ p: 3 }}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <FormControl sx={{ backgroundColor: 'white' }}>

          <InputLabel id="transporter-select-label">
            {t('tank_selection.transporter')}
          </InputLabel>

          <Select
            sx={{ minWidth: 100 }}
            size="small"
            labelId="transporter-select-label"
            id="transporter-select"
            value={transporter}
            label={t('tank_selection.transporter')}
            onChange={handleChangeTransporter}
          >
            {transporters.map((u, i) => (
              <MenuItem key={u} value={u}>
                {u}
              </MenuItem>
            ))}
          </Select>

        </FormControl>
        <Typography variant="body2">
          {t('admin.made')}
          :
        </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
          <DesktopDatePicker
            name="startDate"
            id="startDate"
            label={t('admin.between_start_date')}
            inputFormat="DD/MM/YYYY"
            value={startDate}
            onChange={handleStartDateChange}
          />
          <DesktopDatePicker
            name="endDate"
            id="endDate"
            label={t('admin.between_end_date')}
            value={endDate}
            format="DD/MM/YYYY"
            onChange={handleEndDateChange}
          />
        </LocalizationProvider>
      </Box>
    </Paper>
  );
}
