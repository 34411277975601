// import frLocale from 'date-fns/locale/fr';
// import enLocale from 'date-fns/locale/en-US';

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export async function isUserAdmin(user) {
  if (user !== null) {
    const token = await user.getIdTokenResult();
    if (token?.claims.admin !== undefined) {
      return token.claims.admin === 'true';
    }
  }
  return false;
}

export async function isAlpha(user) {
  const token = await user.getIdTokenResult();
  if (token?.claims.alpha !== undefined) {
    return token.claims.alpha === 'true';
  }
  return false;
}

export function inAdminPage(location) {
  return location.pathname.includes('admin');
}

export function round(value, decimals) {
  return roundNoFixed(value, decimals).toFixed(decimals);
}

export function roundNoFixed(value, decimals) {
  const decimalFix = `${1}e${decimals}`;
  return Number(Math.round((value + Number.EPSILON) * decimalFix) / decimalFix);
}

export function useQuery(useMemo, useLocation) {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function searchInCompartmentArray(value, indexArray, valuesDict) {
  const exactMatch = valuesDict[value];
  if (exactMatch !== undefined) {
    return exactMatch;
  }
  const closestIndexInf = indexArray.reduce((prev, curr) => (curr < value && curr > prev ? curr : prev));
  const closestIndexSup = indexArray[indexArray.indexOf(closestIndexInf) + 1];

  const closestValueSup = valuesDict[closestIndexInf];
  const closestValueInf = valuesDict[closestIndexSup];
  const infToSupDistanceProportion = (value - closestIndexInf) / (closestIndexSup - closestIndexInf);
  const newValue = closestValueSup
    - infToSupDistanceProportion * (closestValueSup - closestValueInf);
  return newValue;
}

export function downloadCsvFile(name, csv) {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = `${name}_${new Date().toLocaleDateString()}.csv`;
  hiddenElement.click();
}

export function replaceComma(value) {
  if (value) {
    return value.toString().replace(/,/, '.');
  }
  return value;
}

export function navigatorNetworkState() {
  // console.log('Navigator online: ', navigator.onLine);
  return navigator.onLine ? 'online' : 'offline';
}

const knownDatabases = ['firebase-heartbeat-database',
  'firebase-installations-database', 'firebaseLocalStorageDb',
  'firebase_remote_config', 'firestore/[DEFAULT]/labox-ws/main'];

const clearIndexedDB = () => {
  if (window.indexedDB.databases) {
    window.indexedDB.databases().then((databases) => {
      databases.forEach((database) => {
        window.indexedDB.deleteDatabase(database.name);
      });
    });
  } else {
  // Fallback to predefined list
    knownDatabases.forEach((dbName) => {
      window.indexedDB.deleteDatabase(dbName);
    });
  }
};

async function clearCacheStorage() {
  const keys = await caches.keys();
  keys.forEach(async (key) => {
    await caches.delete(key);
  });
}

// eslint-disable-next-line no-promise-executor-return
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const refreshSessionAndReload = () => {
  sessionStorage.clear();
  window.location.reload();
};

export const refreshSWAndReload = async () => {
  sessionStorage.clear();
  // This will directly look for the service worker if it is
  // waiting and skip it then reload the page
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((readyRegistration) => {
      if (readyRegistration.waiting) {
        readyRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    });
  }
  await sleep(2000);
  window.location.reload();
};

export const clearApplicationCache = async () => {
  localStorage.clear();
  sessionStorage.clear();

  // Clearing cookies
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }

  await clearIndexedDB();
  await clearCacheStorage();
  await refreshSWAndReload();
};

// UNUSED METHOD COMMENTED BELOW
// MIGHT STILL BE USEFUL SOMEDAY DEPENDING ON APP PERFORMANCE REGARDING OFFLINE MODE

// async function fetchWithTimeout(resource, options = {}) {
//   const { timeout = 8000 } = options;

//   const controller = new AbortController();
//   const id = setTimeout(() => controller.abort(), timeout);
//   const response = await fetch(resource, {
//     ...options,
//     signal: controller.signal,
//   });
//   clearTimeout(id);
//   return response;
// }

// export async function fetchNetworkState(functions) {
//   try {
//     const response = await fetchWithTimeout('http://neverssl.com', {
//       timeout: 6000,
//     });
//     return response.status >= 200 && response.status < 300 ? 'online' : 'offline';
//   } catch (err) {
//     return 'offline';
//   }
// }

// export function navigatorNetworkState2() {
//   // This isn't reliable for a lot of devices
// see: https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
//   // That is why we will use fetchNetworkState to make sure at app start
//   const connection = navigator.connection
//     || navigator.mozConnection
//     || navigator.webkitConnection
//     || navigator.webkitConnection;

//   if (connection?.downlink <= 0.1) {
//     return 'offline';
//   }

//   // This is less precise and for iOS support
//   if (navigator.onLine === false) {
//     return 'offline';
//   }

//   return 'online';
// }
