import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { useNavigate } from 'react-router-dom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function LastOperationsDialog(props) {
  const { t } = useTranslation();
  const {
    setOpen,
    open,
  } = props;

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleItemClick = (opId) => {
    navigate(
      `/operation-pdf?operationId=${encodeURIComponent(opId)}`,
    );
  };

  // read the latest operation ids from localStorage
  const operationIds = JSON.parse(localStorage.getItem('latest-operation-ids') || '[]');

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }}>
        {t('tank_selection.last_operations')}
      </DialogTitle>
      <DialogContent>
        <List>
          {operationIds.map((opId, index) => (
            <React.Fragment key={opId}>
              <ListItem
                button
                sx={{
                  display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1,
                }}
                onClick={() => handleItemClick(opId)}
              >
                <Typography>{opId}</Typography>
                <PictureAsPdfIcon />
              </ListItem>
              {index < operationIds.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
