import React from 'react';
import {
  FormHelperText,
  Box,
  Typography,
  Button,
} from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import ClearIcon from '@mui/icons-material/Clear';

export default function SignatureField({
  formik, handleSignatureChange, handleClearSignature, sigCanvas,
}) {
  return (
    <Box sx={{
      border: 1, width: 310, alignSelf: 'center', position: 'relative',
    }}
    >
      <Typography sx={{
        position: 'absolute',
        top: 5,
        left: 10,
      }}
      >
        Signature:
      </Typography>
      <SignatureCanvas
        ref={sigCanvas}
        onEnd={handleSignatureChange}
        canvasProps={{
          width: 310,
          height: 200,
          className: 'sigCanvas',
        }}
      />
      {formik.errors.signature && formik.touched.signature && (
      <FormHelperText error>{formik.errors.signature}</FormHelperText>
      )}
      <Button
        variant="text"
        size="small"
        onClick={handleClearSignature}
        sx={{
          position: 'absolute',
          top: 1,
          right: 1,
        }}
      >
        <ClearIcon />
      </Button>
    </Box>
  );
}
