import React, { useState } from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { Box, Paper, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function TransportedLiquids({ docId }) {
  const firestore = useFirestore();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newLiquidName, setNewLiquidName] = useState('');
  const [globalState] = useGlobal();
  const [liquidNameError, setLiquidNameError] = useState(false);

  const { t } = useTranslation();

  const docRef = doc(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates`,
  );
  const { data: certificateData } = useFirestoreDocData(docRef);
  const transportedLiquids = certificateData?.transportedLiquids || [];

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setNewLiquidName('');
    setLiquidNameError(false);
  };

  const handleAddLiquid = async () => {
    if (newLiquidName.trim() !== '') {
      await updateDoc(docRef, {
        transportedLiquids: arrayUnion(newLiquidName),
      });
      handleCloseDialog();
    } else {
      setLiquidNameError(true);
    }
  };

  const handleDeleteLiquid = async (liquidName) => {
    await updateDoc(docRef, {
      transportedLiquids: arrayRemove(liquidName),
    });
  };

  const handleReorderLiquid = async (liquid, newIndex) => {
    let newOrder = [...transportedLiquids];

    newOrder = newOrder.filter((item) => item !== liquid);
    newOrder.splice(newIndex, 0, liquid);

    await updateDoc(docRef, {
      transportedLiquids: newOrder,
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h6">{t('programs.transported_liquids')}</Typography>
      <Paper elevation={0} sx={{ p: 3 }}>
        <List>
          {transportedLiquids.map((liquid, index) => (
            <ListItem key={uuidv4()}>
              <ListItemText primary={liquid} />
              <Select
                value={index}
                onChange={(e) => handleReorderLiquid(liquid, e.target.value)}
              >
                {transportedLiquids.map((_, i) => (
                  <MenuItem key={uuidv4()} value={i}>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label={t('delete')}
                  onClick={() => handleDeleteLiquid(liquid)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleOpenDialog}
          sx={{ width: '100%', maxWidth: 600 }}
        >
          {t('add')}
        </Button>

        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogContent>
            <TextField
              label={t('name')}
              value={newLiquidName}
              onChange={(event) => setNewLiquidName(event.target.value)}
              fullWidth
              error={liquidNameError}
              helperText={liquidNameError ? t('validations.required') : ''}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>{t('cancel')}</Button>
            <Button onClick={handleAddLiquid}>{t('add')}</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Box>
  );
}
