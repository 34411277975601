import React from 'react';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

export default function ListProgramItem({ handleOpenProgram, program, setProgramToDelete }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        p: 2,
      }}
      onClick={() => handleOpenProgram(program)}
    >
      <Box sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1,
      }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{program.name}</Typography>
        <Steps steps={program.steps} />
        <Products products={program.products} />
      </Box>
      <Button
        size="small"
        color="error"
        onClick={() => setProgramToDelete(program)}
      >
        {t('delete')}
      </Button>
    </Box>
  );
}

function Steps({ steps }) {
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Typography>{`${t('programs.steps')}: `}</Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {steps.map((s) => (
          <Typography key={s.index}>
            {`-- ${s.index}. ${s.name}`}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}

function Products({ products }) {
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Typography>{`${t('programs.products')}: `}</Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {products.map((p, index) => (
          <Typography key={uuidv4()}>
            {`--- ${p.name}`}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
