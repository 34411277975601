import React from 'react';
import {
  Box,
  TextField,
  Autocomplete,
  Button,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

export default function AddOrModifyWashingSteps({ washingSteps, formik }) {
  const { t } = useTranslation();

  const handleAddStep = () => {
    const newStep = {
      id: uuidv4(),
      index: formik.values.steps.length + 1,
      name: '',
    };

    formik.setFieldValue('steps', [...formik.values.steps, newStep]);
  };

  const handleRemoveStep = (index) => {
    const newSteps = [...formik.values.steps];
    newSteps.splice(index, 1);
    formik.setFieldValue('steps', newSteps);

    // Reassign indexes
    const updatedSteps = newSteps.map((step, idx) => ({
      ...step,
      index: idx + 1,
    }));

    formik.setFieldValue('steps', updatedSteps);
  };

  const options = [...new Set([...washingSteps, ...formik.values.steps.map((s) => s.name)])];
  const filteredOptions = options.filter((option) => option !== null && option.trim() !== '');

  const handleStepChange = (value, index) => {
    const newSteps = [...formik.values.steps];
    newSteps[index].name = value;

    formik.setFieldValue('steps', newSteps);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ gap: 3, width: '100%' }}
    >
      <Typography variant="h6">{t('programs.steps')}</Typography>

      {formik.values.steps.map((step, index) => (
        <Box key={step.id} display="flex" alignItems="center" sx={{ gap: 1, width: '100%', alignItems: 'stretch' }}>
          <Typography sx={{ alignSelf: 'center' }}>
            {step.index}
            .
          </Typography>
          <Autocomplete
            freeSolo
            sx={{ flexGrow: 1, width: 600 }}
            options={filteredOptions}
            value={formik.values.steps[index].name}
            onChange={(event, value) => handleStepChange(value, index)}
            renderInput={(params) => (
              <TextField
                onBlur={() => handleStepChange(params.inputProps.value, index)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    event.stopPropagation();
                    handleStepChange(params.inputProps.value, index);
                  }
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
              />
            )}
          />

          <Button
            sx={{ minWidth: 80 }}
            size="small"
            variant="outlined"
            color="error"
            onClick={() => handleRemoveStep(step.id)}
          >
            {t('delete')}
          </Button>

        </Box>
      ))}
      {formik.errors.steps && (<Typography color="red">{formik.errors.steps}</Typography>)}

      <Button
        variant="outlined"
        size="small"
        onClick={handleAddStep}
        sx={{ justifyContent: 'center', alignItems: 'center', gap: 1 }}
      >
        <AddIcon />
        {t('programs.add_step')}
      </Button>

    </Box>
  );
}
