import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMenu from 'menu-actions/useMenu';
import { useQuery } from './utils';
import StoragePDFViewer from './StoragePDFViewer';

export default function StoragePDFViewerPage(props) {
  const query = useQuery(React.useMemo, useLocation);
  const tankPath = query.get('filePath');
  const { t } = useTranslation();
  const { dispatchMenuActions } = useMenu();

  const downloadPdf = (url) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.target = '_blank';
    a.download = 'certificate.pdf';
    a.click();
    a.remove();
  };

  const downloadPDFAction = (url) => ({
    name: t('download'),
    callback: () => downloadPdf(url),
  });

  return (
    <StoragePDFViewer
      path={tankPath}
      loadedCallback={(url) => dispatchMenuActions({ type: 'set', actions: [downloadPDFAction(url)] })}
    />
  );
}
