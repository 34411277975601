import React from 'react';
import {
  Checkbox,
  TextField,
  FormControlLabel,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import 'moment/locale/fr';
import Done from '@mui/icons-material/Done';
import Switch from '@mui/material/Switch';
import { LoadingButton } from '@mui/lab';
import TransportedLiquidField from './TransportedLiquidField';
import CompartmentsField from './CompartmentsField';
import SignatureField from './SignatureField';
import WashingProgramsField from './WashingProgramsField';

function OperationFormFields({
  appData, washingPrograms, tankId, tankCompartments, sigCanvas,
  formik, handleCheckboxChange, handleMultiSelectChange, handleSignatureChange, handleClearSignature,
}) {
  const { t } = useTranslation();

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    }}
    >
      <Paper
        elevation={0}
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: 2,
          width: '100%',
          maxWidth: 800,
        }}
      >

        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
          <MobileDateTimePicker
            name="dateTime"
            id="dateTime"
            format="DD/MM/YYYY HH:mm"
            label={t('date_and_time')}
            value={formik.values.dateTime}
            onChange={(val) => {
              formik.setFieldValue('dateTime', val);
            }}
          />
        </LocalizationProvider>

        <TransportedLiquidField
          formik={formik}
          appData={appData}
        />

        <FormControlLabel
          sx={{ alignSelf: 'flex-start', ml: 0 }}
          labelPlacement="start"
          control={(
            <Checkbox
              name="noForeignBodies"
              checked={formik.values.noForeignBodies}
              onChange={handleCheckboxChange}
            />
          )}
          label={t('operation.no_foreign_bodies')}
        />

        <Box sx={{
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
        }}
        >
          <Typography sx={{ mr: 3 }}>
            {`${t('operation.flexibles')}: `}
          </Typography>

          <Typography sx={{ textAlign: 'left' }}>
            {t('no')}
          </Typography>
          <Switch
            name="flexibles"
            checked={formik.values.flexibles}
            onChange={formik.handleChange}
            color="primary"
          />
          <Typography sx={{ textAlign: 'left' }}>
            {t('yes')}
          </Typography>

        </Box>
        <CompartmentsField
          formik={formik}
          appData={appData}
          handleMultiSelectChange={handleMultiSelectChange}
          tankCompartments={tankCompartments}
        />

        <WashingProgramsField formik={formik} washingPrograms={washingPrograms} />

        <FormControlLabel
          sx={{ alignSelf: 'flex-start', ml: 0 }}
          labelPlacement="start"
          control={(
            <Checkbox
              name="rinseTest"
              checked={formik.values.rinseTest}
              onChange={handleCheckboxChange}
            />
          )}
          label={t('operation.rinse_test')}
        />

        <Typography sx={{ textAlign: 'left' }}>
          {`${t('operation.used_products')}:${'\u00A0\u00A0\u00A0\u00A0'}${formik.values.products}` }
        </Typography>

        <TextField
          multiline
          rows={3}
          name="plombs"
          label={t('operation.plombs')}
          value={formik.values.plombs}
          onChange={formik.handleChange}
        />

        <TextField
          name="driver"
          label={t('operation.driver')}
          value={formik.values.driver}
          onChange={formik.handleChange}
          error={formik.errors.driver && formik.touched.driver}
          helperText={
          formik.errors.driver
          && formik.touched.driver
          && formik.errors.driver
        }
        />

        <SignatureField
          formik={formik}
          handleSignatureChange={handleSignatureChange}
          handleClearSignature={handleClearSignature}
          sigCanvas={sigCanvas}
        />

        <LoadingButton
          loading={formik.isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          sx={{ alignSelf: 'center' }}
          endIcon={<Done />}
        >
          {t('operation.done')}
        </LoadingButton>
      </Paper>
    </Box>
  );
}

export default OperationFormFields;
