import React, { useState } from 'react';
import { useFirestore, useFirestoreCollectionData, useFirestoreDocDataOnce } from 'reactfire';
import {
  collection,
  deleteDoc,
  query,
  orderBy,
  doc,
} from 'firebase/firestore';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
} from '@mui/material';
import useGlobal from 'global-state/store';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import Divider from '@mui/material/Divider';
import WashingProgramForm from './WashingProgramForm';
import ListProgramItem from './ListProgramItem';

function WashingPrograms() {
  const firestore = useFirestore();
  const [globalState] = useGlobal();
  const [programData, setProgramData] = useState(undefined);

  const washingProgramsRef = collection(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates/washingPrograms`,
  );

  const appDocRef = doc(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates`,
  );

  const washingProgramsQuery = query(
    washingProgramsRef,
    orderBy('name'),
  );

  const { status: programsStatus, data: washingPrograms } = useFirestoreCollectionData(washingProgramsQuery);
  const { status: appDataStatus, data: appData } = useFirestoreDocDataOnce(appDocRef);

  const [openDialog, setOpenDialog] = useState(false);
  const [programToDelete, setProgramToDelete] = useState(null);

  const handleOpenDialog = () => {
    setProgramData(undefined);
    setOpenDialog(true);
  };

  const handleOpenProgram = (program) => {
    setProgramData(program);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteProgram = async (program) => {
    try {
      await deleteDoc(
        doc(
          firestore,
          `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates/`
          + `washingPrograms/${program.NO_ID_FIELD}`,
        ),
      );
      setProgramToDelete(null);
    } catch (error) {
      console.error(error);
    }
  };

  if (programsStatus !== 'success' || appDataStatus !== 'success') {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 3,
    }}
    >
      <Typography variant="h6">{t('programs.washing_programs')}</Typography>

      <Paper elevation={0} sx={{ p: 1, minHeight: 60, width: '100%' }}>
        {washingPrograms.map((program) => (
          <div key={program.NO_ID_FIELD}>
            <ListProgramItem
              handleOpenProgram={handleOpenProgram}
              program={program}
              setProgramToDelete={setProgramToDelete}
            />
            <Divider />
          </div>
        ))}
      </Paper>

      <Button
        variant="contained"
        onClick={handleOpenDialog}
        sx={{ justifyContent: 'center', alignItems: 'center', gap: 1 }}
      >
        <AddIcon />
        {t('programs.create_new')}
      </Button>

      <Dialog
        maxWidth="xl"
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{t('programs.create_new')}</DialogTitle>
        <DialogContent>
          <WashingProgramForm
            onClose={handleCloseDialog}
            washingPrograms={washingPrograms}
            washingSteps={appData.washingSteps || []}
            washingProducts={appData.washingProducts || []}
            modifyWith={programData}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xl"
        open={programToDelete !== null}
        onClose={() => setProgramToDelete(null)}
      >
        <DialogContent>
          <Typography>{t('delete_proceed_warning')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProgramToDelete(null)}>{t('cancel')}</Button>
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => handleDeleteProgram(programToDelete)}
          >
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default WashingPrograms;
