import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function CompartmentsField({
  formik, appData, handleMultiSelectChange, tankCompartments,
}) {
  const { t } = useTranslation();

  return (
    <FormControl>
      <InputLabel id="cpts-label">{t('operation.compartments')}</InputLabel>
      <Select
        id={t('operation.compartments')}
        labelId="cpts-label"
        name={t('operation.compartments')}
        multiple
        value={formik.values.compartments}
        onChange={handleMultiSelectChange}
        renderValue={(selected) => selected.join(', ')}
        input={<OutlinedInput label={t('operation.compartments')} />}
      >
        <MenuItem value="all">
          <Checkbox checked={formik.values.compartments.length === tankCompartments.length} />
          <ListItemText primary={t('all')} />
        </MenuItem>
        {tankCompartments.map((compartment) => (
          <MenuItem key={compartment} value={compartment}>
            <Checkbox checked={formik.values.compartments.includes(compartment)} />
            <ListItemText primary={compartment} />
          </MenuItem>
        ))}
      </Select>
      {formik.errors.compartments && (
      <FormHelperText error>{formik.errors.compartments}</FormHelperText>
      )}
    </FormControl>
  );
}
