import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import {
  query,
  collection,
  getDocs,
  where,
  orderBy,
  limit,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import CircularProgress from '@mui/material/CircularProgress';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Alert from '@mui/material/Alert';
import useGlobal from 'global-state/store';
import moment from 'moment';
import Filters from './OperationsFilters';
import OperationDetailDialog from './OperationDetailDialog';

export default function Operations({ dispatchMenuActions }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const db = useFirestore();
  const [loadingState, setloadingState] = React.useState('initial');
  const [operations, setoperations] = React.useState([]);
  const [activeOperation, setactiveOperation] = React.useState('');
  const [startDate, setStartDate] = React.useState(initialStartDate());
  const [endDate, setEndDate] = React.useState(initialEndDate());
  const all = t('all');
  const [transporterNames, settransporterNames] = React.useState([]);
  const [transporter, setTransporter] = React.useState(all);
  const analytics = getAnalytics();
  const [globalState] = useGlobal();

  const handleChangeTransporter = (event) => {
    if (event.target.value !== transporter) {
      setTransporter(event.target.value);
      setloadingState('initial');
    }
  };

  const handleStartDateChange = (newValue) => {
    const newDate = moment(newValue);
    if (!newDate.isSame(startDate)) {
      if (newDate.isAfter(endDate)) {
        const newEndDate = newDate.clone().endOf('day');
        setEndDate(newEndDate);
      }
      setStartDate(newDate);
      setloadingState('initial');
    }
  };

  const handleEndDateChange = (newValue) => {
    const newDate = moment(newValue);
    if (!newDate.isSame(endDate)) {
      if (newDate.isBefore(startDate)) {
        const newStartDate = newDate.clone().startOf('day');
        setStartDate(newStartDate);
      }
      setEndDate(newDate);
      setloadingState('initial');
    }
  };

  function initialStartDate() {
    return moment().startOf('day').subtract(5, 'days');
  }

  function initialEndDate() {
    return moment().endOf('day');
  }

  const handleOpen = (operation) => {
    setactiveOperation(operation);
    setOpen(true);
  };

  React.useEffect(() => {
    async function getOperationsOfClient(firestore, path) {
      const listOfConditions = [
        where('dateTime', '<=', endDate.toDate()),
        where('dateTime', '>=', startDate.toDate()),
      ];
      if (transporter !== all) {
        listOfConditions.push(where('transporterName', '==', transporter));
      }
      const operationsQuery = query(
        collection(firestore, path, 'operations'),
        ...listOfConditions,
        orderBy('dateTime', 'desc'),
        limit(500),
      );
      const querySnapshot = await getDocs(operationsQuery);
      const { docs } = querySnapshot;

      const newOperations = docs.map((operationDoc) => ({ ...operationDoc.data(), id: operationDoc.id }));

      let newTransporterNames = newOperations.map((v) => v.transporterName);
      const transporterNamesSet = new Set([...newTransporterNames.values()]);
      transporterNamesSet.delete(undefined);
      newTransporterNames = [all].concat([...transporterNamesSet]);
      settransporterNames(newTransporterNames);

      setoperations(newOperations);
      logEvent(analytics, 'loading_operations', {
        appName: 'Digitank, Cleaning Certificates',
        organization: globalState.activeOrganization,
      });
      setloadingState('done');
    }

    async function getOperations() {
      getOperationsOfClient(
        db,
        `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates`,
      );
    }
    if (loadingState === 'initial' && globalState.activeOrganization !== '') {
      getOperations();
    }
  }, [activeOperation.organizationTransporter, all, analytics, db,
    endDate, globalState.activeOrganization, loadingState, startDate, t, transporter]);

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', gap: 2,
    }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('admin.operations')}
      </Typography>
      {loadingState === 'done' && (
      <Filters
        all={all}
        transporter={transporter}
        transporters={transporterNames}
        handleChangeTransporter={handleChangeTransporter}
        startDate={startDate}
        handleStartDateChange={handleStartDateChange}
        endDate={endDate}
        handleEndDateChange={handleEndDateChange}
      />
      )}
      <ListHeader />
      {operations.length >= 500 && <Alert severity="warning">{t('admin.too_many')}</Alert>}

      <Paper elevation={0} sx={{ p: 1 }}>
        {loadingState === 'done' && (
          <List dense sx={{ width: '100%' }}>
            {operations.map((op, i) => (
              <div key={op.id}>
                <ListOperation
                  operation={op}
                  index={i}
                  handleOpen={handleOpen}
                />
                <Divider />
              </div>
            ))}
          </List>
        )}
        {loadingState !== 'done' && <CircularProgress />}
      </Paper>

      <OperationDetailDialog
        operation={activeOperation}
        open={open}
        setOpen={setOpen}
        dispatchMenuActions={dispatchMenuActions}
      />
    </Box>
  );
}

function ListOperation(props) {
  const {
    handleOpen, operation,
  } = props;

  return (
    <ListItem component="div">
      <ListItemButton sx={{ py: 1 }} onClick={() => handleOpen(operation)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: 2,
          }}
        >
          <Typography sx={{ flexGrow: 1, flexBasis: 1 }}>{operation.tankId}</Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1 }}>
            {`${operation.transporterName}`}
          </Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1, textAlign: 'end' }}>
            {new Date(operation.dateTime.toMillis()).toLocaleString()}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}

function ListHeader() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        px: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography>{t('admin.tank_id')}</Typography>
      <Typography>{t('admin.transporter')}</Typography>
      <Typography sx={{ width: 155 }}>{t('admin.date')}</Typography>
    </Box>
  );
}
