import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import {
  Routes, Route, Link,
} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Truck from '@mui/icons-material/LocalShipping';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import ArticleIcon from '@mui/icons-material/Article';
import Operations from './monitoringOperations/Operations';
import WahsingPrograms from './managingPrograms/WashingPrograms';
import TransportedLiquids from './managingPrograms/TransportedLiquids';
import PDFBranding from './pdfBranding/PDFBranding';

export default function Admin({ dispatchMenuActions }) {
  return (
    <Routes>
      <Route path="operations" element={<Operations />} />
      <Route path="programs" element={<WahsingPrograms />} />
      <Route path="transported-liquids" element={<TransportedLiquids />} />
      <Route path="pdf-branding" element={<PDFBranding />} />
      <Route path="" element={<AdminReception />} />
      <Route
        path="*"
        element={(
          <main style={{ padding: '1rem' }}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>There's nothing here!</p>
          </main>
        )}
      />
    </Routes>
  );
}

function AdminReception() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 3,
        px: { xs: 2, md: 10 },
        mt: { xs: 5, md: 10 },
        mb: 15,
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          width: '100%', maxWidth: 300, textAlign: 'left', mt: 3,
        }}
      >
        {t('admin.monitoring')}
        :
      </Typography>
      <Box
        sx={{
          display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 3,
        }}
      >
        <MenuButton
          to="operations"
          icon={<FormatListBulleted sx={{ fontSize: 150 }} />}
          text={t('admin.operations_management')}
        />
      </Box>
      <Typography
        variant="h6"
        component="div"
        sx={{ width: '100%', maxWidth: 300, textAlign: 'left' }}
      >
        {t('admin.management')}
        :
      </Typography>
      <Box
        sx={{
          display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 3,
        }}
      >
        <MenuButton
          to="programs"
          icon={<Truck sx={{ fontSize: 150 }} />}
          text={t('operation.programs')}
        />
        <MenuButton
          to="transported-liquids"
          icon={<WaterDropIcon sx={{ fontSize: 150 }} />}
          text={t('programs.transported_liquids')}
        />
        <MenuButton
          to="pdf-branding"
          icon={<ArticleIcon sx={{ fontSize: 150 }} />}
          text={t('admin.pdf_branding')}
        />
      </Box>
    </Box>
  );
}

function MenuButton(props) {
  const {
    disabled, to, icon, text, component, href, target,
  } = props;

  return (
    <Button
      disabled={disabled}
      color="info"
      sx={{
        p: 2, gap: 2, display: 'flex', flexDirection: 'column', width: 300,
      }}
      variant="contained"
      component={component || Link}
      href={href}
      target={target}
      to={to}
    >
      {icon}
      {text}
    </Button>
  );
}
