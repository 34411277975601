import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import StoragePDFViewer from 'components/StoragePDFViewer';
import useGlobal from 'global-state/store';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import SendCertificateDialog from 'components/operation/SendCertificateDialog';
import { Box, Button, CircularProgress } from '@mui/material';

export default function OperationPDFView(props) {
  const { t } = useTranslation();
  const {
    operationId, addOnClose = undefined,
  } = props;
  const [globalState] = useGlobal();
  const [sendCertifDialogOpen, setsendCertifDialogOpen] = useState(false);
  const [docBlob, setdocBlob] = useState(undefined);

  const storageDocPath = `gs://labox-ws.appspot.com/organizations/${globalState.activeOrganization}/apps`
  + `/digitank-cleaning-certificates/operations/${operationId}.pdf`;

  const downloadPdf = useCallback((url, blob) => {
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = blobUrl;
    a.target = '_blank';
    a.download = `${operationId}.pdf`;
    a.click();
    a.remove();
    // Added this line to revoke the blob URL after its usage
    URL.revokeObjectURL(blobUrl);
  }, [operationId]);

  const printPdf = useCallback((url, blob) => {
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;

    iframe.onload = function () {
      setTimeout(() => {
        try {
          // Try to call the print function
          iframe.contentWindow.print();
        } catch (error) {
          // If print function is blocked, display an error
          console.error('Print function failed.', error);
        }
      }, 0);
    };

    // Append the iframe to the body
    document.body.appendChild(iframe);
  }, []);

  const handleOpenSendCertificate = useCallback((url, blob) => {
    setdocBlob(blob);
    setsendCertifDialogOpen(true);
  }, []);

  const actions = useMemo(
    () => [
      {
        name: t('download'),
        action: downloadPdf,
        icon: <DownloadIcon />,
      },
      {
        name: t('print'),
        action: printPdf,
        icon: <PrintIcon />,
      },
      {
        name: t('send_email'),
        action: handleOpenSendCertificate,
        icon: <AttachEmailIcon />,
      },
    ],
    [downloadPdf, handleOpenSendCertificate, printPdf, t],
  );

  if (!operationId) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <StoragePDFViewer
        path={storageDocPath}
        actionButtons={actions}
      />
      {addOnClose && (
        <Button variant="outlined" onClick={addOnClose} sx={{ width: '100%', maxWidth: 600, alignSelf: 'center' }}>
            {t('close')}
        </Button>
      )}
      <SendCertificateDialog
        open={sendCertifDialogOpen}
        setOpen={setsendCertifDialogOpen}
        docBlob={docBlob}
        docName={operationId}
      />
    </>
  );
}
