import React, { useRef } from 'react';
import { useFormik } from 'formik';
import { useFirestore } from 'reactfire';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import {
  collection, setDoc, Timestamp, doc,
} from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import 'moment/locale/fr';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  getStorage, ref, uploadString, getDownloadURL,
} from 'firebase/storage';
import OperationFormFields from './OperationFormFields';
import createPdf from './createPdf';

function OperationForm({
  appData, washingPrograms, transporterName, tankId, compartments: tankCompartments,
}) {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const analytics = getAnalytics();
  const firestore = useFirestore();
  const sigCanvas = useRef({});
  const navigate = useNavigate();
  const storage = getStorage();

  const operationPath = `organizations/${globalState.activeOrganization}/apps/`
  + 'digitank-cleaning-certificates/operations';

  const operationsRef = collection(
    firestore,
    operationPath,
  );

  const initialValues = {
    dateTime: moment(),
    tankId,
    transportedLiquid: '',
    noForeignBodies: false,
    flexibles: false,
    compartments: [],
    program: '',
    products: '',
    rinseTest: false,
    plombs: '',
    driver: localStorage.getItem('cleaning_certif_operation_form_driver') || '',
    signature: '',
  };

  const validationSchema = Yup.object().shape({
    transportedLiquid: Yup.string().required(t('validations.required')),
    compartments: Yup.array().min(1, t('validations.at_least_one_selected')).required(t('validations.required')),
    program: Yup.string().required(t('validations.required')),
    driver: Yup.string().required(t('validations.required')),
    signature: Yup.string().required(t('validations.required')),
  });

  function storeOpId(operationId) {
    const opIds = JSON.parse(localStorage.getItem('latest-operation-ids') || '[]');
    opIds.unshift(operationId);
    while (opIds.length > 5) {
      opIds.pop();
    }
    localStorage.setItem('latest-operation-ids', JSON.stringify(opIds));
  }

  async function handleSubmit(values) {
    const {
      dateTime, driver,
    } = values;

    localStorage.setItem('cleaning_certif_operation_form_driver', driver);

    const opId = `${tankId}-${dateTime.format('YYMMDDHHmm')}-${uuidv4().substr(0, 8)}`;

    const logoRef = ref(
      storage,
      `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates/logo.jpg`,
    );
    let logoURL = '';
    try {
      logoURL = await getDownloadURL(logoRef);
    } catch (error) {
      console.error('Failed to retrieve logo URL:', error);
    }

    const prog = washingPrograms.find((p) => p.name === values.program);

    const valuesToPDf = { operationId: opId, steps: prog.steps, ...values };
    const pdf = await createPdf(valuesToPDf, t, logoURL, appData.companyPdfDisplayName, opId);
    // pdf.save('Bon_de_lavage.pdf');
    // return;

    const storageDocPath = `gs://labox-ws.appspot.com/${operationPath}/${opId}.pdf`;

    const storageRef = ref(
      storage,
      storageDocPath,
    );

    const valuesToStore = {
      ...values,
      dateTime: Timestamp.fromMillis(dateTime.valueOf()),
      tankId,
      transporterName,
    };

    const opDoc = doc(operationsRef, opId);
    await setDoc(opDoc, valuesToStore);

    const pdfData = pdf.output('datauristring');
    await uploadString(storageRef, pdfData, 'data_url');

    storeOpId(opId);

    logEvent(analytics, 'save_operation', {
      appName: 'Digitank, Cleaning Certificates',
      organization: globalState.activeOrganization,
      tankId,
    });

    navigate(`/operation-pdf?operationId=${
      encodeURIComponent(opId)}`, {
      replace: true,
    });

    // Success message or redirection
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  const handleMultiSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue('compartments', value.includes('all') ? tankCompartments : value);
  };

  const handleSignatureChange = () => {
    const signatureData = sigCanvas.current.toDataURL();
    formik.setFieldValue('signature', signatureData);
  };

  const handleClearSignature = () => {
    sigCanvas.current.clear();
    formik.setFieldValue('signature', '');
  };

  return (
    <OperationFormFields
      appData={appData}
      washingPrograms={washingPrograms}
      tankId={tankId}
      tankCompartments={tankCompartments}
      formik={formik}
      handleCheckboxChange={handleCheckboxChange}
      handleMultiSelectChange={handleMultiSelectChange}
      handleSignatureChange={handleSignatureChange}
      handleClearSignature={handleClearSignature}
      sigCanvas={sigCanvas}
    />
  );
}

export default OperationForm;
