import React from 'react';
import {
  Box,
  TextField,
  Autocomplete,
  Button,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

export default function AddOrModifyWashingProducts({ washingProducts, formik }) {
  const { t } = useTranslation();

  const handleAddProduct = () => {
    const newProduct = {
      id: uuidv4(),
      name: '',
    };

    formik.setFieldValue('products', [...formik.values.products, newProduct]);
  };

  const handleRemoveProduct = (index) => {
    const newProducts = [...formik.values.products];
    newProducts.splice(index, 1);
    formik.setFieldValue('products', newProducts);
  };

  const handleProductChange = (value, index) => {
    const newProducts = [...formik.values.products];
    newProducts[index].name = value;

    formik.setFieldValue('products', newProducts);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ gap: 3, width: '100%' }}
    >
      <Typography variant="h6">{t('programs.products')}</Typography>

      {formik.values.products.map((product, index) => (
        <Box key={product.id} display="flex" alignItems="center" sx={{ gap: 1, width: '100%', alignItems: 'stretch' }}>
          <Autocomplete
            freeSolo
            sx={{ flexGrow: 1, width: 600 }}
            options={washingProducts}
            value={formik.values.products[index].name}
            onChange={(event, value) => handleProductChange(value, index)}
            renderInput={(params) => (
              <TextField
                onBlur={() => handleProductChange(params.inputProps.value, index)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    event.stopPropagation();
                    handleProductChange(params.inputProps.value, index);
                  }
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
              />
            )}
          />

          <Button
            sx={{ minWidth: 80 }}
            size="small"
            variant="outlined"
            color="error"
            onClick={() => handleRemoveProduct(product.id)}
          >
            {t('delete')}
          </Button>

        </Box>
      ))}
      {formik.errors.products && (<Typography color="red">{formik.errors.products}</Typography>)}

      <Button
        variant="outlined"
        size="small"
        onClick={handleAddProduct}
        sx={{ justifyContent: 'center', alignItems: 'center', gap: 1 }}
      >
        <AddIcon />
        {t('programs.add_product')}
      </Button>

    </Box>
  );
}
