import React from 'react';
import {
  FormControl,
  TextField,
  FormHelperText,
  Autocomplete,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function TransportedLiquidField({
  formik, appData,
}) {
  const { t } = useTranslation();

  return (
    <FormControl>
      <Autocomplete
        freeSolo
        id="transportedLiquid"
        name="transportedLiquid"
        options={appData.transportedLiquids || []}
        value={formik.values.transportedLiquid}
        onChange={(_, newValue) => {
          formik.setFieldValue('transportedLiquid', newValue);
        }}
        renderInput={(params) => (
          <TextField
            label={t('operation.transported_liquid')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                formik.setFieldValue('transportedLiquid', params.inputProps.value);
              }
            }}
                // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
          />
        )}
      />
      {formik.errors.transportedLiquid && (
      <FormHelperText error>{formik.errors.transportedLiquid}</FormHelperText>)}
    </FormControl>
  );
}
