import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';
import { addDoc, collection, doc } from 'firebase/firestore';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { CircularProgress, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LoadingButton } from '@mui/lab';
import useGlobal from 'global-state/store';

export default function SendCertificateDialog(props) {
  const { t } = useTranslation();
  const {
    docBlob, docName, setOpen, open,
  } = props;
  const firestore = useFirestore();
  const [success, setSuccess] = useState(false);
  const [loadingState, setLoadingState] = useState('initial');
  const [base64pdf, setbase64pdf] = useState();
  const [globalState] = useGlobal();

  const appDocRef = doc(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates`,
  );
  const { status: appDataStatus, data: appData } = useFirestoreDocDataOnce(appDocRef);

  useEffect(() => {
    function blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result.split(',')[1]);
        };
        reader.readAsDataURL(blob);
      });
    }

    async function setBlobToB64(blob) {
      const res = await blobToBase64(blob);
      setbase64pdf(res);
      setLoadingState('done');
    }

    if (loadingState === 'initial' && docBlob) {
      setBlobToB64(docBlob);
    }
  }, [t, loadingState, docBlob]);

  if (appDataStatus !== 'success') {
    return <CircularProgress />;
  }

  return (
    <DialogWithAppData
      open={open}
      setOpen={setOpen}
      firestore={firestore}
      appData={appData}
      docName={docName}
      base64pdf={base64pdf}
      setSuccess={setSuccess}
      loadingState={loadingState}
      success={success}
    />
  );
}

function DialogWithAppData({
  open, setOpen, firestore, appData, docName, base64pdf, setSuccess, loadingState, success,
}) {
  const { t } = useTranslation();
  const sendEmailWithAttachment = useCallback(async (values) => {
    try {
      await addDoc(collection(firestore, 'mail'), {
        to: values.recipientEmail,
        message: {
          subject: `Bon de lavage ${appData.companyPdfDisplayName || ''}`,
          text: `Message de l'opérateur: ${values.emailText}`
          + `\n\nVeuillez trouver ci-joint le bon de lavage de ${appData.companyPdfDisplayName || ''}`
          + '\n\nCe message vous est envoyé par l\'application Digitank, Bons de Lavage\n\n'
          + '--\nLabox Applications - contact@labox-apps.com',
          attachments: [
            {
              filename: `${docName}.pdf`,
              content: base64pdf,
              encoding: 'base64',
            },
          ],
        },
      });
    } catch (error) {
      console.error('Error adding email document:', error);
    }
  }, [firestore, appData.companyPdfDisplayName, docName, base64pdf]);

  const validationSchema = Yup.object().shape({
    recipientEmail: Yup.string().required(t('validations.required')),
    emailText: Yup.string(),
  });

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setSuccess(false);
  };

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const handleSubmit = async (values) => {
    sendEmailWithAttachment(values);
    setSuccess(true);
    await sleep(500);
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      recipientEmail: '',
      emailText: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      {loadingState !== 'done' && (
        <CircularProgress />
      )}
      {loadingState === 'done' && (
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 2,
        }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>
            {`${t('operation.send_to_address')}:`}
          </Typography>
          <TextField
            disabled={success}
            id="recipientEmail"
            name="recipientEmail"
            label={t('login.email')}
            value={formik.values.recipientEmail}
            onChange={formik.handleChange}
            error={formik.touched.recipientEmail && formik.errors.recipientEmail}
            helperText={formik.touched.recipientEmail && formik.errors.recipientEmail}
          />
          <TextField
            disabled={success}
            id="emailText"
            name="emailText"
            label={t('contact.message')}
            multiline
            rows={4}
            value={formik.values.emailText}
            onChange={formik.handleChange}
            error={formik.touched.emailText && formik.errors.emailText}
            helperText={formik.touched.emailText && formik.errors.emailText}
          />
        </DialogContent>
        {success && (
        <CheckCircleOutlineIcon sx={{ color: 'ourGreen.main', fontSize: 50, width: '100%' }} />
        )}
        <DialogActions>
          <Button onClick={() => { handleClose(); }}>
            {t('cancel')}
          </Button>
          <LoadingButton
            loading={formik.isSubmitting}
            type="submit"
          >
            {t('contact.send')}
          </LoadingButton>
        </DialogActions>
      </form>
      )}
    </Dialog>
  );
}
