import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'components/utils';
import OperationPDFView from './OperationPDFView';

export default function OperationPDFPage(props) {
  const query = useQuery(React.useMemo, useLocation);
  const operationId = query.get('operationId');
  const navigate = useNavigate();

  return (
    <OperationPDFView
      operationId={operationId}
      addOnClose={() => navigate('/')}
    />
  );
}
