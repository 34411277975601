import React from 'react';
import {
  Box, Typography, Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InstallGuide from 'components/account/InstallGuide';
import {
  useNavigate,
  useLocation,
  Link,
} from 'react-router-dom';
import {
  useSigninCheck,
  useFirebaseApp,
} from 'reactfire';
import { SignIn } from 'labox-ws-commons';
import useGlobal from 'global-state/store';
import {
  getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword,
  createUserWithEmailAndPassword, sendEmailVerification,
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import LaboxWS from 'icons/LaboxWS';

export default function SignInPage() {
  const { t } = useTranslation();
  const { data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();
  const [, globalActions] = useGlobal();
  const location = useLocation();
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const analytics = getAnalytics();
  const provider = new GoogleAuthProvider();

  const ourSignInWithPopup = () => signInWithPopup(auth, provider);

  React.useEffect(() => {
    if (signInCheckResult?.signedIn === true) {
      navigate('/', { replace: true });
    }
  }, [navigate, signInCheckResult?.signedIn]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        mt: 2,
        mb: 15,
      }}
    >
      <SignIn
        t={t}
        globalActions={globalActions}
        navigate={navigate}
        location={location}
        Link={Link}
        auth={auth}
        analytics={analytics}
        gtcuCookieName="agreed-to-general-terms-digitank-cleaning-certificates"
        ourSignInWithPopup={ourSignInWithPopup}
        signInWithEmailAndPassword={signInWithEmailAndPassword}
        createUserWithEmailAndPassword={createUserWithEmailAndPassword}
        logEvent={logEvent}
        sendEmailVerification={sendEmailVerification}
        LaboxWSIcon={LaboxWS}
      />
      <Typography variant="h5" component="div" sx={{ mt: 5 }}>
        {t('welcome.welcome_title')}
      </Typography>
      <Paper elevation={0} sx={{ p: 3, mb: 5 }}>
        <Typography component="p">{t('welcome.welcome_text')}</Typography>
      </Paper>
      <InstallGuide />
    </Box>
  );
}
