import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  TextField,
  Button,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useFirestore } from 'reactfire';
import {
  collection,
  writeBatch,
  doc,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import AddOrModifyWashingSteps from './AddOrModifyWashingSteps';
import AddOrModifyWashingProducts from './AddOrModifyWashingProducts';

function WashingProgramForm({
  modifyWith, washingPrograms, washingSteps, onClose, washingProducts,
}) {
  const firestore = useFirestore();
  const [globalState] = useGlobal();
  const { t } = useTranslation();
  const [initialValues] = useState(initializeValues());

  const washingProgramsRef = collection(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates/washingPrograms`,
  );

  function initializeValues() {
    if (!modifyWith) {
      return {
        name: '',
        steps: [{
          id: uuidv4(),
          index: 1,
          name: '',
        }],
        products: [],
      };
    }

    const updatedSteps = modifyWith.steps.map((step) => ({
      ...step,
      id: uuidv4(),
    }));

    const updatedProducts = modifyWith.products.map((product) => ({
      ...product,
      id: uuidv4(),
    }));

    return {
      ...modifyWith,
      steps: updatedSteps,
      products: updatedProducts,
    };
  }

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = t('validations.required');
    }

    if (values.steps.length === 0) {
      errors.steps = t('programs.at_least_one_step');
    } else {
      const stepNames = values.steps.map((step, index) => step.name?.trim());
      if (stepNames.includes('')) {
        errors.steps = t('programs.empty_step_names');
      }
    }

    const productNames = values.products.map((product, index) => product.name?.trim());
    if (productNames.includes('')) {
      errors.products = t('programs.empty_product_names');
    }

    return errors;
  };

  const handleSubmit = async (values) => {
    try {
      // Check if the program name already exists
      const programNameExists = washingPrograms.some(
        (program) => program.name === values.name,
      );
      if (programNameExists && !modifyWith) {
      // Program name already exists
        formik.setFieldError('name', t('programs.duplicate_name'));
        return;
      }

      const batch = writeBatch(firestore);
      const programSteps = values.steps.map((s) => ({ index: s.index, name: s.name }));
      const programProducts = values.products.map((p) => ({ name: p.name }));

      if (modifyWith) {
        const programDocRef = doc(washingProgramsRef, modifyWith.NO_ID_FIELD);
        batch.update(programDocRef, { name: values.name, steps: programSteps, products: programProducts });
      } else {
        const programDocRef = doc(washingProgramsRef, uuidv4());
        batch.set(programDocRef, { name: values.name, steps: programSteps, products: programProducts });
      }

      const newSteps = values.steps
        .map((step) => step.name)
        .filter((name) => !washingSteps.includes(name));

      const newProducts = values.products
        .map((product) => product.name)
        .filter((name) => !washingProducts.includes(name));

      if (newSteps.length > 0) {
        const appRef = doc(
          firestore,
          `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates`,
        );
        batch.update(
          appRef,
          { washingSteps: [...washingSteps, ...newSteps] },
        );
      }

      if (newProducts.length > 0) {
        const appRef = doc(
          firestore,
          `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates`,
        );
        batch.update(
          appRef,
          { washingProducts: [...washingProducts, ...newProducts] },
        );
      }

      await batch.commit();
      onClose();

      // Success message or redirection
    } catch (error) {
      console.error(error);
      // Error handling
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: handleSubmit,
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ gap: 3, width: '100%' }}
    >
      <TextField
        fullWidth
        size="large"
        name="name"
        label={t('name')}
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        sx={{ mt: 2 }}
      />

      <AddOrModifyWashingSteps washingSteps={washingSteps} formik={formik} />
      <AddOrModifyWashingProducts washingProducts={washingProducts} formik={formik} />

      <Button
        disabled={!formik.isValid}
        type="submit"
        variant="contained"
        sx={{ justifyContent: 'center', alignItems: 'center', gap: 1 }}
      >
        {t('save')}
        <DoneIcon />
      </Button>
    </Box>
  );
}

export default WashingProgramForm;
