import {
  doc, query, collection, getDocs, getDoc,
} from 'firebase/firestore';

function setSelectDefaultTransporterAndTank(newTransportersAndTanks, setTransporter, setTank) {
  const savedTransporterAndTank = detectSavedTransporterAndTank(
    newTransportersAndTanks,
  );
  if (savedTransporterAndTank) {
    setTransporter(savedTransporterAndTank.transporter);
    setTank(savedTransporterAndTank.tank);
  } else {
    const firstTransporter = Object.keys(newTransportersAndTanks)[0];

    setTransporter(firstTransporter);
    setTank(newTransportersAndTanks[firstTransporter][0]);
  }
}

function getSavedTransporterAnTank() {
  let savedTransporterAndTank = localStorage.getItem(
    'saved-transporter-and-tank',
  );
  if (savedTransporterAndTank) {
    savedTransporterAndTank = JSON.parse(savedTransporterAndTank);
  }
  return savedTransporterAndTank;
}

function detectSavedTransporterAndTank(newTransportersAndTanks) {
  let savedTransporterAndTank = getSavedTransporterAnTank();
  if (savedTransporterAndTank) {
    const savedTransporter = savedTransporterAndTank.transporter;
    const savedTank = savedTransporterAndTank.tank;

    const foundSavedTank = newTransportersAndTanks[savedTransporter]?.find(
      (at) => at.id === savedTank,
    );
    if (foundSavedTank) {
      savedTransporterAndTank = {
        transporter: savedTransporter,
        tank: foundSavedTank,
      };
    } else {
      localStorage.removeItem('saved-transporter-and-tank');
      savedTransporterAndTank = undefined;
    }
  }
  return savedTransporterAndTank;
}

async function getTankIds(firestore, transp) {
  const tanksQuery = query(collection(firestore, `${transp.ref.path}/tanks`));
  const querySnapshot = await getDocs(tanksQuery);
  const tanksIdsPromises = querySnapshot.docs.map(async (tankDoc) => {
    const tankDataRef = doc(firestore, `organizations/${transp.id}/apps/digitank-transporter/tanks/${tankDoc.id}`);
    const tankData = await getDoc(tankDataRef);
    return {
      id: tankDoc.id,
      path: tankData.ref.path,
      transporterName: tankData.data().transporterName,
    };
  });
  const tanksIds = await Promise.all(tanksIdsPromises);
  return tanksIds;
}

function groupTanksByTransporterIdAndName(transportersAndTanks) {
  const groupedTanks = {};

  Object.keys(transportersAndTanks).forEach((transporterId) => {
    const transporterTanks = transportersAndTanks[transporterId].tanks;
    Object.keys(transporterTanks).forEach((tankId) => {
      const tank = transporterTanks[tankId];
      const key = `${transportersAndTanks[transporterId].orgName}::${tank.transporterName}`;
      if (!groupedTanks[key]) {
        groupedTanks[key] = [];
      }
      groupedTanks[key].push(tank);
    });
  });
  return groupedTanks;
}

export default async function getTransporterIdsAndTankIds(
  db,
  globalState,
  settransportersAndTanks,
  setloadingState,
  setTransporter,
  setTank,
) {
  const transportersQuery = query(
    collection(db, `organizations/${globalState.activeOrganization}/apps/digitank-tanker-trucks`, 'transporters'),
  );
  const querySnapshot = await getDocs(transportersQuery);
  const transporterDocs = querySnapshot.docs;

  let newTransportersAndTanks = {};

  const transporterTanksPromises = transporterDocs.map(async (transp) => {
    const transporterTanks = await getTankIds(transp.ref.firestore, transp);
    const orgRef = doc(db, `organizations/${transp.id}`);
    const orgDoc = await getDoc(orgRef);
    newTransportersAndTanks[transp.id] = { tanks: transporterTanks, orgName: orgDoc.data().name };
  });

  await Promise.all(transporterTanksPromises);
  newTransportersAndTanks = groupTanksByTransporterIdAndName(newTransportersAndTanks);
  setSelectDefaultTransporterAndTank(newTransportersAndTanks, setTransporter, setTank);
  settransportersAndTanks(newTransportersAndTanks);
  setloadingState('loaded');
}
