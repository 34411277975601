import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function WashingProgramsField({
  formik, washingPrograms,
}) {
  const { t } = useTranslation();

  return (
    <FormControl>
      <InputLabel id="prgms-label">{t('operation.program')}</InputLabel>
      <Select
        labelId="prgms-label"
        id="program"
        name="program"
        value={formik.values.program}
        onChange={(e) => {
          const progName = e.target.value;
          const prog = washingPrograms.find((p) => p.name === progName);
          const prods = prog.products.map((p) => p.name).join(', ');
          formik.setValues({
            ...formik.values,
            program: progName,
            products: prods || t('none'),
          });
        }}
        input={<OutlinedInput label={t('operation.program')} />}
      >
        {washingPrograms.map((washingProgram, index) => (
          <MenuItem
            key={washingProgram.name}
            value={washingProgram.name}
          >
            <ProgramItem washingProgram={washingProgram} index={index} />
          </MenuItem>
        ))}
      </Select>
      {formik.errors.program && (
      <FormHelperText error>{formik.errors.program}</FormHelperText>)}
    </FormControl>
  );
}

function ProgramItem({ washingProgram, index }) {
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      border: 1,
      p: 1,
      borderRadius: 1,
      backgroundColor: index % 2 === 0 ? '#F2F5F8' : 'white',
    }}
    >
      <Typography sx={{ fontWeight: 'bold' }}>
        {washingProgram.name}
      </Typography>
      <Steps steps={washingProgram.steps} />
    </Box>
  );
}

function Steps({ steps }) {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px 20px' }}>
      {steps.map((s) => (
        <Typography key={s.index}>
          {`${s.index}. ${s.name}`}
        </Typography>
      ))}
    </Box>
  );
}
