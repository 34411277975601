import * as React from 'react';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import {
  doc, getDoc, collection, query, orderBy,
} from 'firebase/firestore';
import { useQuery } from 'components/utils';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobal from 'global-state/store';
import { useFirestore, useFirestoreCollectionData, useFirestoreDocDataOnce } from 'reactfire';
import { t } from 'i18next';
import OperationForm from './OperationForm';

export default function Operation() {
  const analytics = getAnalytics();
  const queryParams = useQuery(React.useMemo, useLocation);
  const tankPath = queryParams.get('tankPath');
  const tankPathElements = tankPath.toString().split('/');
  const transporterId = tankPathElements[1];
  const tankId = tankPathElements[5];
  const [globalState] = useGlobal();
  const firestore = useFirestore();
  const [certifStatus, setcertifStatus] = React.useState('initial');

  const [certificate, setCertificate] = React.useState(null);

  const washingProgramsRef = collection(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates/washingPrograms`,
  );

  const appDocRef = doc(
    firestore,
    `organizations/${globalState.activeOrganization}/apps/digitank-cleaning-certificates`,
  );

  const tankDocRef = doc(
    firestore,
    `organizations/${transporterId}/apps/digitank-transporter/tanks/${tankId}`,
  );

  const washingProgramsQuery = query(
    washingProgramsRef,
    orderBy('name'),
  );

  const { status: programsStatus, data: washingPrograms } = useFirestoreCollectionData(washingProgramsQuery);
  const { status: appDataStatus, data: appData } = useFirestoreDocDataOnce(appDocRef);
  const { status: tankStatus, data: tank } = useFirestoreDocDataOnce(tankDocRef);

  React.useEffect(() => {
    if (tankStatus === 'success' && certifStatus === 'initial') {
      logEvent(analytics, 'loading_certificate_for_operation', {
        appName: 'Digitank, Cleaning Certificates',
        tankId,
        organization: globalState.activeOrganization,
      });
      setcertifStatus('loading');
      const certificateRef = doc(
        firestore,
        `organizations/${transporterId}/apps/digitank-transporter/tanks/`
          + `${tankId}/gaugeCertificates/${tank.activeCertificate}`,
      );

      const fetchCertificate = async () => {
        const certificateData = await getDoc(certificateRef);
        if (certificateData.exists()) {
          setCertificate(certificateData.data());
          setcertifStatus('success');
        } else {
          setcertifStatus('error');
        }
      };

      fetchCertificate();
    }
  }, [firestore, transporterId, tankId, tankStatus,
    tank, certifStatus, analytics, globalState.activeOrganization]);

  if (programsStatus === 'error' || appDataStatus === 'error' || certifStatus === 'error') {
    return <LoadingError />;
  }

  if (programsStatus !== 'success' || appDataStatus !== 'success' || certifStatus !== 'success') {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        mt: 3,
        gap: 2,
      }}
    >
      <Typography variant="h6">{`${t('tank_selection.tank')}: ${tankId}`}</Typography>
      <OperationForm
        appData={appData}
        washingPrograms={washingPrograms}
        tankId={tankId}
        transporterName={tank.transporterName}
        compartments={certificate.compartments.map((c) => c.id)}
      />

    </Box>
  );
}

function LoadingError() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      mt: 3,
      gap: 2,
    }}
    >

      <Typography
        component="span"
        sx={{ maxWidth: 800 }}
      >
        {t('operation.loading_operation_error')}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => window.location.reload()}
        endIcon={<ReplayIcon />}
      >
        {t('reload')}
      </Button>
    </Box>
  );
}
