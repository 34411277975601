import { jsPDF } from 'jspdf';
import moment from 'moment';

export default async function createPdf(values, t, logoURL, companyName) {
  const labels = {
    operationId: 'Bon de lavage N° :',
    tankId: 'Citerne : ',
    dateTime: 'Date et Heure :',
    transportedLiquid: 'Liquide transporté avant lavage :',
    noForeignBodies: 'Absence de Corps étrangers :',
    flexibles: 'Flexibles :',
    compartments: 'Compartiments :',
    program: 'Programme :',
    steps: 'Étapes de lavage :',
    rinseTest: 'Test de rinçage :',
    products: 'Produits utilisés :',
    plombs: 'Plombs N° / Commentaires :',
    driver: 'Nom du conducteur :',
  };

  // eslint-disable-next-line new-cap
  const pdf = new jsPDF('p', 'pt', 'a4');

  if (logoURL) {
    const logoImage = await fetch(logoURL);
    const logoImageData = await logoImage.blob();
    const base64Logo = await convertBlobToBase64(logoImageData);

    const MAX_LOGO_WIDTH = 120;
    const MAX_LOGO_HEIGHT = 75;

    const tempImage = new Image();

    await new Promise((resolve, reject) => {
      tempImage.onload = resolve;
      tempImage.onerror = reject;
      tempImage.src = base64Logo;
    });

    const imageWidth = tempImage.width;
    const imageHeight = tempImage.height;

    let logoWidth;
    let logoHeight;

    if (imageWidth > imageHeight) {
      logoWidth = MAX_LOGO_WIDTH;
      logoHeight = (MAX_LOGO_WIDTH / imageWidth) * imageHeight;
    } else {
      logoHeight = MAX_LOGO_HEIGHT;
      logoWidth = (MAX_LOGO_HEIGHT / imageHeight) * imageWidth;
    }

    const logoX = 50;
    const logoY = 80 - (logoHeight / 2);

    pdf.addImage(base64Logo, 'JPEG', logoX, logoY, logoWidth, logoHeight);
  }

  pdf.setFontSize(18);
  let y = 50; // Initial vertical position
  //   const pageWidth = pdf.internal.pageSize.getWidth();
  if (companyName) {
    const companyNameWidth = pdf.getStringUnitWidth(companyName)
      * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
    const companyNameX = (pdf.internal.pageSize.getWidth() - companyNameWidth) / 2;
    pdf.text(companyName, companyNameX, y);
    y += 25;
  }

  pdf.setFontSize(14);
  pdf.text('Bon d\'enregistrement Citerne', 210, y);
  pdf.text('Opérations d\'hygiène', 240, y += 25);

  y = 140;

  Object.keys(labels).forEach((key) => {
    const value = values[key];
    pdf.setFontSize(12);
    pdf.setFont('Helvetica', 'bold'); // Set the font to bold
    pdf.text(labels[key], 50, y);
    pdf.setFont('Helvetica', 'normal'); // Reset the font to normal

    let displayValue;

    if (key === 'flexibles' || key === 'noForeignBodies') {
      displayValue = value ? 'oui' : 'non';
    } else if (key === 'rinseTest') {
      displayValue = value ? 'conforme' : 'non conforme';
    } else if (key === 'dateTime') {
      displayValue = moment(value).format('DD/MM/YYYY HH:mm');
    } else if (key === 'steps') {
      displayValue = value.map((s) => `${s.index}-${s.name}`).join('  ');
    } else if (key === 'products') {
      displayValue = value.toString();
      const maxWidth = 330;
      const lineHeight = 20;
      const lines = pdf.splitTextToSize(displayValue, maxWidth);
      lines.forEach((line, i) => {
        pdf.text(line, 250, y + (i * lineHeight));
      });
      y += (lines.length * lineHeight);
      return;
    } else {
      displayValue = value.toString();
    }

    if (key === 'plombs' || key === 'steps') {
      const maxWidth = 500;
      const lineHeight = 20;
      const lines = pdf.splitTextToSize(displayValue, maxWidth);
      lines.forEach((line, i) => {
        pdf.text(line, 50, y + 20 + (i * lineHeight));
      });
      y += 43 + (lines.length * lineHeight);
    } else {
      pdf.text(displayValue, 250, y);
      y += 35;
    }
  });

  const imgData = values.signature;
  pdf.setFont('Helvetica', 'bold'); // Set the font to bold for the signature label
  pdf.text('Signature :', 50, y);
  pdf.setFont('Helvetica', 'normal'); // Reset the font to normal

  // Add a square around the signature
  pdf.rect(170, y, 200, 150);
  pdf.addImage(imgData, 'JPEG', 170, y, 200, 150);

  return pdf;
}

function convertBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
